import Choices from "choices.js";
import classNames from "classnames";

export const initChoices = () => {
  const choicesJSelementsMultiple =
    document.getElementsByClassName("selectmultiple");
  const choicesJSelements = document.getElementsByClassName("select");
  [...choicesJSelements, ...choicesJSelementsMultiple].map((element) => {
    return initChoice(element);
  });
};

export const createNewChoicesElemenet = ({
  element,
  explodeItemAfterDash,
  additionalclasses,
  errorselector,
  maxItemCountNumber,
  removeItemButton,
  shouldSort,
  position,
  searchEnabled,
}) => {
  const choicesInstance = new Choices(element, {
    callbackOnCreateTemplates: (template) => {
      return {
        fuseOptions: {
          include: "score",
          threshold: 0,
        },
        item(globalClasses, data, removeButton) {
          const ariaSelected = data.active ? 'aria-selected="true"' : "";
          const ariaDisabled = data.disabled ? 'aria-disabled="true"' : "";

          const localClasses = classNames(globalClasses.item, {
            [globalClasses.highlightedState]: data.highlighted,
            [globalClasses.itemSelectable]: !data.highlighted,
            [globalClasses.placeholder]: data.placeholder,
          });

          const removeItemButtonTemplate = removeButton
            ? `<button
         type="button"
         class="${globalClasses.button}"
         data-button
         aria-label="Remove item: '${data.value}'"
         >
         Remove item
       </button>`
            : "";

          return template(`
      <div
        class="${localClasses}"
        data-item
        data-id="${data.id}"
        data-value="${data.value}"
        data-custom-properties='${data.customProperties}'
        data-deletable
        ${ariaSelected}
        ${ariaDisabled}
        >
        ${explodeItemAfterDash ? getFirstLabelPart(data.label) : data.label}
        ${removeItemButtonTemplate}
      </div>
    `);
        },
      };
    },
    classNames: {
      containerInner: `${
        additionalclasses ? additionalclasses : "choices__inner"
      } ${errorselector ? errorselector : ""} `,
    },
    itemSelectText: "",
    loadingText: "🔎...",
    maxItemCount: maxItemCountNumber,
    searchPlaceholderValue: "🔎",

    maxItemText: (maxItemCount) => {
      return `Sie können höchstens ${maxItemCount} Einträge auswählen`;
    },
    noChoicesText: "Keine Auswahl verfügbar",
    noResultsText: "Keine Ergebnisse",
    removeItemButton,
    searchFloor: 1,
    shouldSort,
    searchEnabled,
    position,
  });
  element.addEventListener(
    "addItem",
    () => {
      choicesInstance.hideDropdown();
    },
    false
  );
  return choicesInstance;
};

const getFirstLabelPart = (label) => {
  const SEPARATOR = "-";
  if (label.includes(SEPARATOR)) {
    const labelParts = label.split(SEPARATOR);
    return labelParts[0].trim();
  } else {
    return label;
  }
};
export function initChoice(element) {
  const {
    errorselector,
    maxitemcount,
    explodeitemafterdash,
    additionalclasses,
    shouldsort,
    searchenabled,
    position,
  } = element.dataset;
  const shouldSort = shouldsort !== "false";
  const searchEnabled = searchenabled !== "false";
  const maxItemCountNumber = Number(maxitemcount);
  const explodeItemAfterDash = explodeitemafterdash === "true";
  return createNewChoicesElemenet({
    additionalclasses,
    element,
    errorselector,
    explodeItemAfterDash,
    maxItemCountNumber,
    removeItemButton: true,
    shouldSort: shouldSort,
    searchEnabled: searchEnabled,
    position,
  });
}
