/* Project specific Javascript goes here. */

import * as bootstrap from "bootstrap";
import Dropzone from "dropzone";
import htmx from "htmx.org/dist/htmx";
import * as Ladda from "ladda";
import { DataTable } from "simple-datatables";
import "../scss/project.scss";
import { initChoice, initChoices } from "./utils/choicesConfig";
require("ladda/dist/ladda-themeless.min.css");

window.Dropzone = Dropzone;
Dropzone.autoDiscover = false;

require("expose-loader?exposes=Choices!choices.js");

window.htmx = htmx;
window.bootstrap = bootstrap;
window.DataTable = DataTable;
window.initChoices = initChoices;
window.initChoice = initChoice;
window.bootstrap = bootstrap;
window.debounce = require("lodash/debounce");
window.Ladda = Ladda;
// initialize all bootstrap toasts
var toastElList = [].slice.call(document.querySelectorAll(".toast"));
toastElList.map(function (toastEl) {
  var bsAlert = new bootstrap.Toast(toastEl, {});
  bsAlert.show(); //show it
});
// use Ladda for all forms
Ladda.bind("button[type=submit]");

// handle bfcache
window.addEventListener("pageshow", (event) => {
  // restore Ladda buttons if user hits back button
  if (event.persisted) {
    Ladda.stopAll();
  }
});

window.ready = (callbackFunc) => {
  if (document.readyState !== "loading") {
    // Document is already ready, call the callback directly
    callbackFunc();
  } else if (document.addEventListener) {
    // All modern browsers to register DOMContentLoaded
    document.addEventListener("DOMContentLoaded", callbackFunc);
  } else {
    // Old IE browsers
    document.attachEvent("onreadystatechange", function () {
      if (document.readyState === "complete") {
        callbackFunc();
      }
    });
  }
};
